<template>
  <div id="privacy-policy">
    <v-container>
      <v-row>
        <v-col>
          <Terms />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Terms from "@/termsAndConditions/de.md";
export default {
  components: {
    Terms,
  },
};
</script>
